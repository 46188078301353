












































import Vue from "vue";
import db from "@/firebase/db";
import fb from "firebase/app";
import DBHelper from "@/tscript/dbHelper";
import MycUploadFiles from "./MycUploadFiles.vue";
import { checkI18 } from "@/tscript/utils";
import _ from "lodash";
import MycButton from "@/components/global/buttons/MycButton.vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  components: { MycUploadFiles, MycButton },
  props: {
    patientId: String,
    medicalCaseId: String,
  },
  name: "myc-card-exam",
  data() {
    return {
      dbHelper: new DBHelper(db),
      filesList: new Array(),
      linkIds: new Array(),
      medicalCase: {} as any,
    };
  },

  methods: {
    ...mapActions("snackbar", ["setSnackbarAction"]),
    checkI18,
    addFile(event: any) {
      this.filesList.push(event);
    },
    deleteFile(event: any) {
      this.filesList = _.filter(this.filesList, function (f) {
        return f.download_url !== event.download_url;
      });
    },
    async saveMedicalCase() {
      const medicalToSave = this.medicalCase;
      medicalToSave.done_by = this.userData?.id;
      medicalToSave.last_update = fb.firestore.Timestamp.now();
      medicalToSave.patient_id = this.patientId;
      let resultSave: any = null;
      delete medicalToSave.id;
      if (this.medicalCaseId == "new") {
        medicalToSave.created_at = fb.firestore.Timestamp.now();
        resultSave = await this.dbHelper.addDataToCollection(
          "patients_medical",
          medicalToSave
        );
      } else {
        resultSave = await this.dbHelper.setDataToCollection(
          "patients_medical",
          this.medicalCaseId,
          medicalToSave
        );
      }
      medicalToSave.id = this.medicalCaseId;
      if (resultSave) {
        this.setSnackbarAction({
          status: true,
          message: this.$i18n.t("snackbar.data_saved"),
          type: "success",
        });
      } else {
        this.setSnackbarAction({
          status: true,
          message: this.$i18n.t("snackbar.error"),
          type: "error",
        });
      }
      this.$emit("medicalCaseSaved");
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
  },

  async created() {
    if (this.medicalCaseId == "new") {
      const ref = db.collection("patients_medical").doc();
      this.medicalCase.id = ref.id;
    } else {
      this.medicalCase.id = this.medicalCaseId;
      this.medicalCase = await this.dbHelper.getDocFromCollection(
        "patients_medical",
        this.medicalCase.id
      );
      const constraints: any = {
        where: [
          {
            field: "deep_link_ids",
            compare: "array-contains",
            value: this.medicalCase.id,
          },
          {
            field: "type",
            compare: "==",
            value: "medical",
          },
        ],
      };

      this.filesList = await this.dbHelper.getAllDataFromCollectionWithAll(
        "files_attachment",
        constraints
      );
    }
  },
});
