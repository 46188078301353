import { render, staticRenderFns } from "./PatientFolder.vue?vue&type=template&id=53ac0652&scoped=true&"
import script from "./PatientFolder.vue?vue&type=script&lang=ts&"
export * from "./PatientFolder.vue?vue&type=script&lang=ts&"
import style0 from "./PatientFolder.vue?vue&type=style&index=0&id=53ac0652&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ac0652",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
installComponents(component, {VDataIterator})
