






















































import Vue from "vue";
import i18n from "@/i18n";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import { getAge } from "@/tscript/utils/index";
import { mapState } from "vuex";

export default Vue.extend({
  name: "myc-card-patient",
  props: {
    patient: Object,
    gap: {
      type: Number,
      default: 1,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideInformations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
    };
  },
  methods: {
    editPatient() {
      this.$emit("editPatient");
    },
    getAge,
    getSexe() {
      if (this.patient.sex === "M") {
        return i18n.t("global.male");
      } else if (this.patient.sex === "F") {
        return i18n.t("global.female");
      } else {
        return i18n.t("global.sex");
      }
    },
    getCountry(countryCode: string) {
      const countryPatient = this.countriesList.find(
        (country: any) => country.value == countryCode
      );
      return countryPatient?.flag + " " + countryPatient?.value;
    },
  },
  computed: {
    ...mapState("countries", ["countriesList"]),
  },
});
