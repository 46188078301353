

























import Vue from "vue";

export default Vue.extend({
  name: "DialogOkCancel",
  props: ["title", "body", "complementaryData"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    okAction() {
      this.dialog = false;
      this.$emit("dialogOK");
    },
    cancelAction() {
      this.dialog = false;
      this.$emit("dialogCancel");
    },
  },
  watch: {
    body: function () {
      if (this.body) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
});
