








































import Vue from "vue";
import fb from "firebase/app";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
//import { BACKEND_URL } from "@/config/index";
import i18n from "@/i18n";

const vue2Dropzone = require("vue2-dropzone");
const uuid = require("uuid");

export default Vue.extend({
  name: "FileInput",
  props: [
    "label",
    "accept",
    "prependIcon",
    "id",
    "path",
    "reset",
    "employeeData",
    "filesList",
    "fileType",
    "isDropZone",
  ],
  components: { vueDropzone: vue2Dropzone },
  data() {
    return {
      error: false,
      errorMsg: "",
      successMsg: "",
      files: [],
      docsUrls: [],
      dragAndDrop: false,
      dropzoneOptions: {
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        url: "https://myc-telemedicine.web.appspot.com/dropzone/",
        maxFilesize: 10,
        dictDefaultMessage: i18n.t("global.dropzone_message"),
      },
    };
  },
  methods: {
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    uploadFile(file: any) {
      if (
        this.filesList.length != 0 &&
        this.fileType == "checkup_certificate"
      ) {
        this.$emit("errorAddCertificateFile");
      } else {
        if (!this.isDropZone) {
          (this.$refs.fileInput as any).$refs.input.click();
        }
        const arrayFile: any = file?.name ? [file] : [];
        this.onFileChange(arrayFile);
      }
    },
    async handleSubmitFile(file: any) {
      if (file?.size > 10000000) {
        this.error = true;
        this.errorMsg = this.$root.$t("global.file_10M").toString();
        return false;
      } else {
        // No Error
        if (file) {
          let fileName = this.id;
          if (!this.id) {
            fileName = this.uuidv4();
          }
          this.error = false;
          this.errorMsg = "";
          const extension = file.name?.split(".").pop();
          const path =
            "clients/" + this.path + "/" + fileName + "." + extension;
          const storageRef = fb.storage().ref(path);
          const url = await storageRef.put(file).then(async function () {
            const pictureRef = fb.storage().ref().child(path);
            return await pictureRef
              .getDownloadURL()
              .then(function (url: any) {
                return url;
              })
              .catch(() => {});
          });
          if (this.isDropZone) {
            const vd: any = this.$refs.vueDropzone;
            vd?.removeAllFiles();
          }
          if (url) {
            return { name: file.name, url: url };
          }
          return true;
        }
      }
    },
    async onFileChange(files: any) {
      const docsUrl = [];
      for (var i = 0; i < files?.length; ++i) {
        const result: any = await this.handleSubmitFile(files[i]);
        const userLoggedId = this.$store.state.userId;
        const newDoc: any = {
          name: result.name,
          url: result.url,
          last_updated_by: userLoggedId,
        };
        docsUrl.push(newDoc);
        if (!result) {
          this.files = [];
          return false;
        }
      }
      this.dragAndDrop = false;
      this.$emit("fileUploaded", docsUrl);
    },
    addDropFile(e: any) {
      this.dragAndDrop = true;
      this.files = Array.from(e.dataTransfer.files);
    },
  },

  watch: {
    reset: function () {
      if (this.reset) {
        this.files = [];
      }
    },
    files: function () {
      if (this.files && this.dragAndDrop) {
        this.onFileChange(this.files);
      }
    },
  },
});
