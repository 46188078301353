















































import Vue from "vue";
import { mapState } from "vuex";
import fb from "firebase/app";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import MycCard from "@/components/global/cards/MycCard.vue";
import FileInput from "@/components/global/form/FileInput.vue";
import MycFileListInput from "@/components/global/form/MycFileListInput.vue";

export default Vue.extend({
  components: { MycCard, FileInput, MycFileListInput },
  props: {
    nocard: {
      type: Boolean,
      default: false,
    },
    linkIds: Array,
    filesList: Array,
    fileType: String,
    toolbar: Boolean,
    isInCard: {
      type: Boolean,
      default: false,
    },
    isDropZone: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  name: "myc-upload-files",
  data() {
    return {
      dbHelper: new DBHelper(db),
    };
  },

  methods: {
    async uploadFile(urlData: any) {
      if (urlData && urlData?.length == 1) {
        const newFile: any = {
          name: urlData[0].name,
          download_url: urlData[0].url,
          last_updated_by: this.$store.state.userId,
          deep_link_ids: this.linkIds,
          date: fb.firestore.Timestamp.now(),
          type: this.fileType,
        };
        const result = await this.dbHelper.addDataToCollection(
          "files_attachment",
          newFile
        );
        if (result?.id) {
          newFile.id = result.id;
          this.$emit("addFileAttachment", newFile);
        }
      }
    },
    async deleteFile(fileToDelete: any) {
      await this.dbHelper.deleteData("files_attachment", fileToDelete.id);
      this.$emit("deleteFileAttachment", fileToDelete);
    },
  },

  computed: {
    ...mapState("user", ["userData"]),
  },
});
