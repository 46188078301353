


















































import Vue from "vue";
import fb from "firebase/app";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import DialogOKCancel from "@/components/global/DialogOKCancel.vue";
import i18n from "@/i18n";
import * as _ from "lodash";
import { mapState } from "vuex";
export default Vue.extend({
  name: "myc-file-list-input",
  components: { DialogOKCancel },
  data() {
    return {
      dbHelper: new DBHelper(db),
      showFiles: new Array(),
      nameDialog: "",
      titleDialog: "",
      fileToDelete: {},
    };
  },
  props: {
    files: Array,
    type: {
      type: String,
      default: "medical",
    },
    toolbar: Boolean,
    update: Function,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getFilesByType();
  },
  watch: {
    type: function (type: any) {
      this.getFilesByType();
    },
    files: function () {
      this.getFilesByType();
    },
    toolbar: function () {
      this.getFilesByType();
    },
  },
  methods: {
    getFilesByType() {
      this.showFiles = new Array();
      const files: any = this.files;
      for (let i = 0; i < this.files.length; i++) {
        if (files[i].type === this.type) {
          this.showFiles.push(files[i]);
        }
      }
    },
    goToFile(file: any) {
      window.open(file.download_url, "_blank");
    },
    getFileName(fileName: any) {
      let fileCut = fileName.split("", 20);
      return fileCut.join("") + "...";
    },
    getDate(index: any) {
      let result = "";
      const seconds: any =
        (this.files[index] as any).date._seconds ??
        (this.files[index] as any).date.seconds;
      if (seconds) {
        const fullDate = new Date(seconds * 1000);
        result =
          ("0" + fullDate.getDate()).slice(-2) +
          "/" +
          ("0" + (fullDate.getMonth() + 1)).slice(-2) +
          "/" +
          fullDate.getFullYear();
      }
      return result;
    },
    deleteFile(file: any) {
      this.titleDialog = i18n.t("global.question_document_delete").toString();
      this.nameDialog = file.name;
      this.fileToDelete = file;
    },
    async okAction() {
      this.nameDialog = "";
      const fileToDelete: any = this.fileToDelete;
      const urlToDelete = fileToDelete.download_url;
      const storage = fb.storage();
      const storageRef = storage.refFromURL(urlToDelete);
      fileToDelete["file_ref"] = storageRef;
      await storageRef
        .delete()
        .then(async function () {})
        .catch(function (error) {
          return error;
        });
      this.update(fileToDelete);
    },
    resetAction() {
      this.nameDialog = "";
    },
  },

  computed: { ...mapState("user", ["userData"]) },
});
