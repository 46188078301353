

















































import { getColor } from "@/tscript/utils/index";
import Vue from "vue";
import DBHelper from "@/tscript/dbHelper";
import db from "@/firebase/db";
import * as _ from "lodash";
import { mapState } from "vuex";

export default Vue.extend({
  name: "myc-card-out-patient",
  props: {
    consultation: Object,
    isInWaitingRoom: {
      type: Boolean,
      default: false,
    },
    actions: Array,
    type: String,
    outpatientDisplay: String,
  },
  data() {
    return {
      expand: false,
      getColor: getColor,
      dbHelper: new DBHelper(db),
      lastprescription: new Array(),
      prescriptionLines: new Array(),
    };
  },
  methods: {
    getDoctorName(doctorId: string) {
      let doctorFullName = "";
      if (doctorId == this.userData?.id) {
        doctorFullName =
          this.userData.last_name + " " + this.userData.first_name;
      } else {
        const usersList = this.$store.state.usersList;
        const doctor = _.find(usersList, (user: any) => user.id == doctorId);
        doctorFullName = doctor
          ? doctor.last_name + " " + doctor.first_name
          : "";
      }
      return doctorFullName;
    },
    editMedicalCase() {
      this.$emit("editMedicalCase", this.consultation.id);
    },
    getDate(date: any) {
      let result = "";
      if (date) {
        const fullDate = new Date(date * 1000);
        result =
          ("0" + fullDate.getDate()).slice(-2) +
          "/" +
          ("0" + (fullDate.getMonth() + 1)).slice(-2) +
          "/" +
          fullDate.getFullYear();
      }
      return result;
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
});
