



































































// @ is an alias to /src
import Vue from "vue";
import db from "@/firebase/db";
import fb from "firebase/app";
import i18n from "@/i18n";
import DBHelper from "@/tscript/dbHelper";
import * as _ from "lodash";
import MycDialog from "@/components/global/dialog/MycDialog.vue";

// Components
import MycPageLayout from "@/layout/MycPageLayout.vue";
import MycCard from "@/components/global/cards/MycCard.vue";
import MycCardPatient from "@/components/global/cards/MycCardPatient.vue";
import Header from "@/components/global/Header.vue";
import MycCardInvestigation from "@/components/global/cards/MycCardInvestigation.vue";
import MycCardConsultation from "@/components/global/cards/MycCardConsultation.vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  name: "PatientFolder",
  components: {
    MycPageLayout,
    MycDialog,
    MycCard,
    MycCardPatient,
    Header,
    MycCardInvestigation,
    MycCardConsultation,
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
      patientId: "",
      getParam: "",
      getParamExtraData: "",
      fileType: "medical",
      model: {} as any,
      medicalCasesList: new Array(),
      schema: new Array(),
      code: "",
      noDataToDiplay: i18n.t("global.no_data"),
      patientMedicalExtraData: {} as any,
      dialogMedicalCase: false,
      medicalCaseId: "new",
      medicalCaseToShare: "",
    };
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    ...mapActions("snackbar", ["setSnackbarAction"]),
    goBack() {
      this.$router.push({ name: "home-patients" }).catch(() => {});
    },
    showFile(event: any) {
      this.fileType = event;
    },
    editMedicalCase(event: any) {
      this.dialogMedicalCase = true;
      this.medicalCaseId = event;
    },
    editPatient() {
      this.$router
        .push({
          name: "account",
          params: { id: this.patientId },
        })
        .catch(() => {});
    },
    getSexe(sex: any) {
      if (sex === "M") {
        return i18n.t("global.male");
      } else if (sex === "F") {
        return i18n.t("global.female");
      } else {
        return i18n.t("global.sex");
      }
    },
    closeDialog() {
      this.dialogMedicalCase = false;
    },
    getAge(birthday: any) {
      let dateOfBirth: any = new Date(birthday);
      const diff: any = Date.now() - dateOfBirth.getTime();
      const age = new Date(diff);
      return Math.abs(age.getUTCFullYear() - 1970);
    },
    async saveNotes() {
      const medicalExtraDataToFb: any = this.patientMedicalExtraData;
      if (!medicalExtraDataToFb?.client_id && this.userData?.client_id) {
        medicalExtraDataToFb["client_id"] = this.userData.client_id;
      }
      if (
        !medicalExtraDataToFb?.site_id &&
        this.userData?.role.includes("SITE") &&
        this.userData?.site_ids?.length > 0
      ) {
        medicalExtraDataToFb["site_id"] = this.userData.site_ids;
      }
      medicalExtraDataToFb["last_update_by"] = this.userData.id;
      medicalExtraDataToFb["type"] = "note";
      const dataPost: any = {
        patient_id: this.patientId,
        get_param: this.getParamExtraData,
        data: medicalExtraDataToFb,
        save_history: true,
      };

      const result =
        await this.$store.state.apiClient.postSavePatientMedicalExtraData(
          dataPost
        ); // faire la route API pour sauvegarder en BDD
      if (result?.success) {
        this.$store.dispatch("patientsListAction", null);
        this.setSnackbarAction({
          status: true,
          message: this.$i18n.t("snackbar.data_saved"),
          type: "success",
        });
      } else {
        this.setSnackbarAction({
          status: true,
          message: this.$i18n.t("snackbar.error"),
          type: "error",
        });
      }
    },

    saveMedicalCase() {
      this.dialogMedicalCase = false;
      this.loadData();
    },

    async loadData() {
      if (this.getParam != "new") {
        this.patientId = this.getParam;
        const patientDataResult = await this.dbHelper.getDocFromCollection(
          "patients_admin",
          this.patientId
        );
        this.model = patientDataResult;
      } else {
        const ref = db.collection("patients_admin").doc();
        this.patientId = ref.id;
        const localCountry = _.find(this.countriesList, {
          value: this.$i18n.locale.toUpperCase(),
        });
        if (localCountry) {
          (this.model as any).prefix =
            localCountry.callingCodes[0]?.substring(1);
          (this.model as any).country = localCountry.value;
        }
      }

      // Load Data Cases
      if (this.userData) {
        const arrayWhere: any = {
          patient_id: this.patientId,
        };
        this.medicalCasesList =
          await this.dbHelper.getAllDataFromCollectionWithWhereArray(
            "patients_medical",
            arrayWhere
          );
      }
    },
    checkAuth(userData: any) {
      if (userData) {
        this.loadData();
      }
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("initialRoute", ["initialRoute"]),
    ...mapState("countries", ["countriesList"]),
  },
  async created() {
    fb.analytics().logEvent("admin_page_open");
    this.getParam = this.$route.params.id;
    this.patientId = this.$route.params.patient_id;
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setAppointmentsList" && state?.appointmentsList) {
        this.loadData();
      }
    });

    this.checkAuth(this.userData);
  },
  watch: {
    userData: function (userData) {
      this.checkAuth(userData);
    },
  },
});
